import './App.css';

function App() {
  return (
    <div>
      <h1>
        Welcome to my website
      </h1>
      <p>
        This is sample website built with REACT
      </p>
    </div>
  );
}

export default App;
